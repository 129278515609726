import React, { useLayoutEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import IntroductionLayout from "../../container/IntroductionLayout"
import { graphql, useStaticQuery } from "gatsby"

const Content =styled.div`
  display: flex;
  border-bottom: 5px solid black;
  border-top: 5px solid black;
  padding: 60px 10px;
  position: relative;
`
const ScrollDetector = styled.div`
  position: sticky;
  top: 25px;
  display: flex;
  height: 500px;
  
  @media screen and (max-width: 768px) {
    top: 80px;
    transform: translateX(-10px);
  }
`

const ScrollShapeContainer = styled.div`
  overflow: hidden;
  width: 50px;
  height: 500px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  
  @media screen and (max-width: 768px) {
    width: 30px;
  }
`;

const ScrollBarContainer = styled.div`
  overflow: hidden;
  width: 80px;
  height: 500px;
  left: 50px;
  margin-right: 20px;
  padding: 20px 0;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ScrollCircle = styled.div`
   border: 10px solid var(--second-color);
   border-radius: 20px;
   width: 10px;
   height: 10px;
   transition: transform .3s;
   ${props => props.isScaled && css`
     transform: scale(2);
  `};
   
  @media screen and (max-width: 768px) {
    border-width: 5px;
  }
`;

const ScrollDash = styled.div`
   border-right: 10px solid var(--second-color);
   width: 10px;
   height: 70px;
   
  @media screen and (max-width: 768px) {
    border-width: 5px;
   width: 5px;
  }
`;

const ScrollBar = styled.div`
  border-top: 2px black dashed;
  width: 70px;
  height: 2px;
  position: absolute;
  transition: top .1s;
`

const DescriptionContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

const Description = styled.div`
  padding-bottom: 50px;
    
  :last-child {
    height: 500px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

const DescriptionTitle = styled.h3`
  color: var(--second-color);
  margin-bottom: 20px;
`;

const DescriptionContentContainer = styled.div`
  color: black;
`;

const DescriptionRow = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const DescriptionDate = styled.div`
  display: inline-block;
  margin-right: 30px;
  width: 100px;
  
  @media screen and (max-width: 768px) {
    width: 50px;
  }
`;

const DescriptionContent = styled.div`
  display: inline-block;
  word-break: break-word;
  width: 650px;
  @media screen and (max-width: 768px) {
    width: 210px;
  }
`;

function WorkHistory () {
  const [descriptionIndex, setDescriptionIndex] = useState(0);
  const scrollBar = useRef();
  const {
    allWorkHistoryJson: {
      edges
    }
  } = useStaticQuery(graphql`
      query {
          allWorkHistoryJson {
              edges {
                  node {
                      name
                      history {
                          date
                          content
                      }
                  }
              }
          }
      }
  `)

  useLayoutEffect(() => {

    function makeScrollCallback() {
      const isBrowser = typeof window !== `undefined`
      if(!isBrowser) return
      let tick = false

      return function onScroll() {
        if(tick)
          return

        tick = true
        return requestAnimationFrame(() => {
          const descriptions = document.getElementsByClassName('description');
          let offset = window.pageYOffset - 495
          let nowHeight = 0, allHeight = 0
          if(offset < 0)
            offset = 0
          for(let i = 0; i < descriptions.length; i++) {
            allHeight += descriptions[i].clientHeight
          }
          if (offset / allHeight * 460 > 460)
            offset = allHeight
          for(let i = 0; i < descriptions.length; i++) {
            nowHeight += (descriptions[i].clientHeight - 300)
            if (nowHeight >= offset) {
              setDescriptionIndex(i)
              break
            }
            nowHeight += 300
          }

          if(scrollBar.current)
            scrollBar.current.style.top = (offset / allHeight * 460 + 20) + 'px'

          tick = false
        })
      }
    }

    document.addEventListener('scroll', makeScrollCallback())
    return () => {
      document.removeEventListener('scroll', makeScrollCallback())
    }
  }, [])

  return (
    <IntroductionLayout title="주요 연혁">
      <Content>
        <ScrollDetector>
          <ScrollShapeContainer>
            <ScrollCircle isScaled={descriptionIndex === 0} />
            <ScrollDash />
            <ScrollCircle isScaled={descriptionIndex === 1} />
            <ScrollDash />
            <ScrollCircle isScaled={descriptionIndex === 2} />
            <ScrollDash />
            <ScrollCircle isScaled={descriptionIndex === 3} />
          </ScrollShapeContainer>
          <ScrollBarContainer>
            <ScrollBar ref={scrollBar} />
          </ScrollBarContainer>
        </ScrollDetector>
        <DescriptionContainer id="description-container">
          {edges.map(({ node }) => (
            <Description
              className={`description`}
              key={node.name}>
              <DescriptionTitle>
                {node.name}
              </DescriptionTitle>
              <DescriptionContentContainer>
                {node.history.map(({date, content}) => (
                  <DescriptionRow key={content}>
                    <DescriptionDate>{date}</DescriptionDate>
                    <DescriptionContent>{content}</DescriptionContent>
                  </DescriptionRow>
                ))}
              </DescriptionContentContainer>
            </Description>
          ))}
        </DescriptionContainer>
      </Content>
    </IntroductionLayout>
  )
}

export default WorkHistory;